<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col>
        <v-select
            v-if="contacts && contacts.length>1"
            v-model="contact"
            :items="contacts"
            item-text="data"
            item-value="data"
            return-object

        >

        </v-select>
        <p v-else> {{ contacts[0].data }}</p>
      </v-col>
      <v-col>
        <response-suggestion-menu :conversation="conversation" @updateText="changeInputText"/>
      </v-col>
      <v-col>
        <v-btn icon @click="rephrase()" class="mx-5">
          <v-icon>mdi-chat-processing-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters justify="space-around">
      <v-col cols="12">
        <v-textarea
            v-model="inputText"
            class="my-0"
            auto-grow
            rows="1"
            @click="onFocus"
            @input="onChange"
            @keyup.down="onArrowDown"
            @keyup.up="onArrowUp"
            :label="overEmail?'email':'sms'"

        >
          <template v-slot:prepend>
              <v-btn icon @click="sendMessage(false)" class="mx-5"
                     :loading="sending"

              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
          </template>
          <template v-slot:append-outer>
            <v-btn icon @click="sendMessage(true)" class="mx-5" text
                   :loading="sending">
              <v-icon color="red">mdi-send</v-icon>
            </v-btn>
          </template>

        </v-textarea>
        <ul
            v-if="isOpen"
            :id="`autocomplete-results-${conversation.id}`"
            class="autocomplete-results"
        >
          <li v-if="isLoading" class="loading">Loading templates...</li>
          <li
              v-for="(result, i) in results"
              v-else
              :key="i"
              class="autocomplete-result"
              :class="{ 'is-active': i === arrowCounter }"
              tabIndex="-1"
              @click="setInputText(result)"
          >
            {{ result }}
          </li>
        </ul>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import tools from "@/util/tools";
import ResponseSuggestionMenu from "@/components/ResponseSuggestionMenu";

export default {
  components: {ResponseSuggestionMenu},
  name: "MessageForm",
  props: ["conversation", "kind"],
  data() {
    return {
      inputText: "",
      search: "",
      searchIndex: 0,
      results: [],
      isOpen: false,
      isLoading: false,
      items: [],
      arrowCounter: 0,
      templatesLoaded: false,
      templateId: 0,
      address: '',
      sending: false,
    };
  },

  computed: {
    ...mapGetters("inventory", ["getDomainByName"]),
    ...mapState("templates", ["templates"]),

    contact: {
      get() {
        if (this.address) return this.address;
        let lastMessage = this.conversation.messages ?
            this.conversation.messages.filter(m => !m.user).sort((a, b) => a.id - b.id).pop() :
            null;
        if (lastMessage && lastMessage.type === 'TEXT') return this.contacts.find(c => c.type === 'phone' || c.type === 'MEDIA');
        else return this.contacts.find(c => c.type === 'email');
      },
      set(val) {
        this.address = val;
      }
    },

    contacts() {
      let contacts = [];
      console.log("Getting contacts from", this.conversation)

      if (this.conversation.email) {
        let emails = tools.parseEmails(this.conversation.email)
        for (const email of emails) {
          contacts.push({
            icon: 'mdi-email',
            data: email,
            type: 'email'
          })
        }
      }
      if (this.conversation.phone) {
        contacts.push({
          icon: 'mdi-phone',
          data: this.conversation.phone,
          type: 'phone'
        });
      }
      console.log("Got contacts ", contacts)

      return contacts;

    },
    overEmail() {
      return this.address ? this.address.type === 'email' :
          this.contact ? this.contact.type === 'email' : false;
    }
  },

  methods: {
    ...mapActions("conversations", ["sendMessageAction", "rephraseAction"]),
    created() {
      this.cancelled = this.message.cancelled;
    },

    changeInputText(data) {
      this.inputText = data.text;
      this.templateId = data.templateId;
      this.$store.commit("conversations/UPDATE_TEXT", {id: this.conversation.id, inputText: data.text});
      this.searchIndex = this.inputText.trim().length;
    },

    onFocus() {
      this.$store.commit("conversations/SET_ACTIVE_CONVERSATION_ID", this.conversation.id);
      this.$store.dispatch("templates/loadAllTemplates");
      if (!this.templatesLoaded) {
        this.loadTemplates(this.conversation);
        this.templatesLoaded = true;
      }
    },
    onChange() {
      this.search = this.inputText.substring(this.searchIndex).trim();
      this.filterResults();
      this.isOpen = true;
      this.$nextTick(() => {
        this.adjustSize();
      });
      this.$store.commit("conversations/UPDATE_TEXT", {id: this.conversation.id, inputText: this.inputText});

    },
    adjustSize() {
      // let list = document.getElementById(
      //     `autocomplete-results-${this.conversation.id}`
      // );
      // console.log("Got the list", list);
      // let totalHeight = 0;
      // for (let i = 0; i < list.children.length; i++) {
      //   totalHeight += list.children[i].sollHeight;
      // }
      // console.log("Got the list height", totalHeight);
      // setTimeout(() => {
      //   list.style.height = totalHeight + "px";
      // }, 1);
    },
    loadTemplates(conversation) {
      this.isLoading = true;
      const templateVars = {
        domain: conversation.domain,
        price: conversation.suggestedPrice,
        sibling: conversation.sibling,
        contact: conversation.contactName,
        inventoryDomain: this.getDomainByName(conversation.domain) ?? '',
      };
      let filled = this.templates.map((r) =>
          this.fillResponseTemplate(r.id, r.text, templateVars)
      );
      this.items = filled.filter(f => f);
      this.isLoading = false;
    },

    fillResponseTemplate(id, templateString, templateVars) {
      const fillTemplate = function (templateString, templateVars) {
        return new Function("return `" + templateString + "`;").call(
            templateVars
        );
      };
      try {
        let filledTemplate = fillTemplate(templateString, templateVars);
        return {id: id, color: "blue", text: filledTemplate};
      } catch (err) {
        console.log("Could not fill template ", templateString)
        console.log('Templates Vars', templateVars)
        return null;
      }
    },

    filterResults() {
      // first uncapitalize all the things
      this.results = this.items
          .filter((item) => {
            return (
                item.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            );
          })
          .map((r) => r.text);
    },

    setInputText(result) {
      if (this.inputText && this.inputText.length > 20) {
        this.inputText = this.inputText.substring(0, this.inputText.lastIndexOf(this.search)) + " " + result;
      } else {
        this.inputText = result;
      }
      this.search = '';
      this.searchIndex = this.inputText.trim().length;


    },
    onArrowDown() {
      if (!this.isOpen) {
        this.onChange();
      }
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
        console.log("Arrow Counter: " + this.arrowCounter);
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },

    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },

    async sendMessage(overrideValidation) {
      const template = this.items.find(i => i.text === this.inputText);
      const itemId = template ? template.id : null;
      const message = {
        template: {
          id: itemId ? itemId : this.templateId,
        },
        overrideValidation: overrideValidation,
        overEmail: this.overEmail,
        text: this.inputText,
        conversation: {
          id: this.conversation.id,
        },
      };

      try {
        this.sending=true;
        await this.sendMessageAction(message);
        this.inputText = "";
      }catch (err) {
        console.error(err);
      }
      this.sending=false;


    },
    async rephrase() {
      console.log("rephrasing: ", this.inputText);
      this.inputText = await this.rephraseAction({text: this.inputText});
    },

  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.inputText = this.conversation.inputText;

  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },


};
</script>
<style scoped>

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid hsl(261, 54%, 35%);
  height: 120px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: hsl(261, 54%, 35%);
  color: white;
}
</style>
